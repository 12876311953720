import _ from "lodash";
import React, { Component } from "react";
import PropTypes, { func } from "prop-types";
import ReactDOM from "react-dom";
import { Button, Row } from "antd";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import "./override/LabelUtil";
import "./override/LabelEditingProvider";
import "./override/ReplaceOptions";
import BpmnModeler from "bpmn-js/lib/Modeler";
import bpiumRenderer from "./plugins/bpiumRenderer";
import i18n from "../../../configs/i18n";
import ButtonClose from "../../common/UI/ButtonClose";

import ContextPad from "./plugins/context-pad";
import Keyboard from "./plugins/keyboard";
import Outline from "./plugins/outline";
import camundaModdleDescriptor from "camunda-bpmn-moddle/resources/camunda";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";

import ElementHelper from "bpmn-js-properties-panel/lib/helper/ElementHelper";
import { formatInput, formatOutput } from "../helpers/parameters";
import { formatScriptValue } from "../helpers/parameters/types/value";
import { createElementsHelper } from "../helpers/createElementsHelper";

function upsert(arr, newValue, where) {
  const index = _.findIndex(arr, where);
  if (index > -1) {
    arr[index] = newValue;
  } else {
    return arr.push(newValue);
  }
  return index;
}

function inputValueMapper(value, { type, config }) {
  switch (type) {
    case "pair":
      return value.map(o => ({
        key: o.value,
        value: {
          type: "script",
          value: o.subValue
        }
      }));

    case "dropdown":
      return value.map(value => ({
        type: "const",
        value
      }));

    case "object":
      return {
        type: "const",
        value: _.get(value, "0.key")
      };

    default:
      return {
        type: config.valueType || "script",
        value
      };
  }
}

class Editor extends Component {
  constructor(props) {
    super(props);
    this.modeler = null;
    this.canvas = null;
    this.toolbar = _.reduce(
      this.props.toolbar,
      (result, component) => {
        if (component.hidden) {
          return result;
        }
        if (result[component["class"]]) {
          result[component["class"]].push(component);
        } else {
          result[component["class"]] = [component];
        }
        return result;
      },
      {}
    );
    this.onButtonLassoSelect = this.onButtonLassoSelect.bind(this);
    this.onButtonLink = this.onButtonLink.bind(this);
    this.createAction = this.createAction.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setValues = this.setValues.bind(this);
  }

  async componentDidMount() {
    let bpmn = this.props.bpmn;
    if (this.props.file && this.props.file.url) {
      bpmn = await fetch(this.props.file.url).then(data => data.text());
      // bpmn = bpmn.split('type = "bpmn:Process"')
    }

    this.modeler = new BpmnModeler({
      container: ReactDOM.findDOMNode(this.ref),
      moddleExtensions: {
        camunda: camundaModdleDescriptor
      },
      additionalModules: [
        {
          __init__: ["bpiumRenderer"],
          bpiumRenderer: [
            "type",
            bpiumRenderer(this.props.toolbar, this.props.t)
          ]
        },
        Outline,
        ContextPad,
        Keyboard,
        {
          __depends__: [
            require("diagram-js/lib/command"),
            require("diagram-js/lib/features/change-support"),
            require("diagram-js-direct-editing")
          ],
          __init__: ["labelEditingProvider"],
          labelEditingProvider: [
            "type",
            require("./override/LabelEditingProvider")["default"]
          ]
        }
      ]
    });
    this.modeler.importXML(bpmn, err => {
      if (!err) {
        this.modeler.get("canvas").zoom("fit-viewport");
      } else {
      }
    });

    this.canvas = this.modeler.get("canvas");
    const overlays = this.modeler.get("overlays");
    const elementRegistry = this.modeler.get("elementRegistry");
    const eventBus = this.modeler.get("eventBus");
    eventBus.on("selection.changed", e => {
      if (e.newSelection.length === 1) {
        this.props.onSelectElement(e.newSelection[0]);
      } else {
        this.props.onSelectElement(null);
      }
    });
  }

  onButtonLassoSelect() {
    this.modeler.get("lassoTool").activateSelection();
  }

  onButtonLink() {
    this.modeler.get("globalConnect").toggle();
  }

  onButtonElement(component) {
    return this.createAction(
      component.element,
      component["class"],
      null,
      component.title,
      {
        service: component.service
      }
    ).action;
  }

  createAction(type, group, className, title, options) {
    const create = this.modeler.get("create");
    const elementFactory = this.modeler.get("elementFactory");
    const bpmnFactory = this.modeler.get("bpmnFactory");

    const createListener = event => {
      const shape = elementFactory.createShape(
        _.assign(
          {
            type: type === "bpmn:ErrorEventDefinition" ? "bpmn:EndEvent" : type
          },
          options
        )
      );
      shape.width = 64;
      shape.height = 64;
      if (options) {
        shape.businessObject.di.isExpanded = options.isExpanded;
        shape.businessObject.extensionElements = createElementsHelper(
          bpmnFactory,
          "bpmn:ExtensionElements",
          {
            values: child => [
              child("camunda:Connector", {
                connectorId: options.service
              })
            ]
          },
          shape.businessObject
        );
      }
      elementFactory.create("label", {
        type: "label",
        parent: shape.parent,
        labelTarget: shape
      });
      if (type === "bpmn:ErrorEventDefinition") {
        shape.businessObject.extensionElements = ElementHelper.createElement(
          "bpmn:ErrorEventDefinition",
          {},
          shape.businessObject,
          bpmnFactory
        );
      }
      create.start(event, shape);
      this.modeler.get("labelEditingProvider").update(shape, title);
    };

    return {
      group: group,
      className: className,
      title: title || "",
      action: {
        dragstart: createListener,
        click: createListener
      }
    };
  }

  onSave() {
    this.modeler.saveXML({ format: true }, (err, xml) => {
      if (!err) {
        this.props.saveFn(xml);
      } else {
        alert("Ошибка сохранения сценария");
        console.error(err);
      }
    });
  }

  updateElement(element) {
    const eventBus = this.modeler.get("eventBus");
    eventBus.fire("element.changed", { element });
  }

  getExtensions(element, type) {
    if (!element.extensionElements) {
      return null;
    }
    return element.extensionElements.filter(function(e) {
      return true;
    });
  }

  setValues(element, values, configs) {
    const bpmnFactory = this.modeler.get("bpmnFactory");
    // const elementFactory = this.modeler.get('elementFactory');
    const moddle = this.modeler.get("moddle");
    if (!element || !values || _.isEmpty(values)) {
      return null;
    }
    const businessObject = getBusinessObject(element);

    const extensionElements =
      businessObject.extensionElements ||
      createElementsHelper(
        bpmnFactory,
        "bpmn:ExtensionElements",
        { values: [] },
        businessObject
      );

    const inputOutput = createElementsHelper(
      bpmnFactory,
      "camunda:InputOutput",
      {},
      extensionElements
    );
    upsert(extensionElements.values, inputOutput, {
      $type: "camunda:InputOutput"
    });

    const inputParameters = [];
    const outputParameters = [];
    Object.assign(businessObject, { extensionElements });
    Object.assign(inputOutput, { inputParameters, outputParameters });

    _.forEach(values, (value, name) => {
      switch (name) {
        case "name":
          this.modeler.get("modeling").updateLabel(element, value);
          return;
        case "description":
          businessObject.documentation = [
            createElementsHelper(
              bpmnFactory,
              "bpmn:Documentation",
              { text: value },
              businessObject
            )
          ];
          return;
      }

      const field = configs.find(e => e.id === name);

      if (!field) {
        return;
      }

      const { type, config = {} } = field;

      switch (config.map) {
        case "output":
          if (value) {
            switch (type) {
              case "pair":
                value.forEach(({ value, subValue }) => {
                  outputParameters.push(
                    formatOutput(bpmnFactory, inputOutput, {
                      name: value,
                      configName: name,
                      value: {
                        type: "script",
                        value: subValue
                      }
                    })
                  );
                });
                break;
              default:
                outputParameters.push(
                  formatOutput(bpmnFactory, inputOutput, {
                    name: value,
                    configName: name,
                    value: {
                      type: "outputResult",
                      value: name
                    }
                  })
                );
            }
          }
          break;
        case "expression":
          businessObject.conditionExpression = createElementsHelper(
            bpmnFactory,
            "bpmn:FormalExpression",
            {
              language: "JavaScript",
              // server support: https://github.com/paed01/bpmn-engine/blob/v4.2.0/lib/activities/SequenceFlow.js
              body: formatScriptValue({
                type: "script",
                value
              })
            },
            businessObject
          );
          break;
        case "timer":
          businessObject.eventDefinitions = [
            createElementsHelper(
              bpmnFactory,
              "bpmn:TimerEventDefinition",
              {
                timeDuration: child =>
                  child("bpmn:FormalExpression", {
                    language: "JavaScript",
                    body: value

                    // check server support before:
                    // https://github.com/paed01/bpmn-engine/blob/v4.2.0/lib/events/TimerEvent.js
                    // body: formatScriptValue({
                    //   type: 'script',
                    //   value,
                    // })
                  })
              },
              businessObject
            )
          ];
          break;
        case "outputErrorCode":
          _.set(businessObject, "eventDefinitions.0.errorCodeVariable", value);
          break;
        case "outputErrorMessage":
          _.set(
            businessObject,
            "eventDefinitions.0.errorMessageVariable",
            value
          );
          break;
        default:
          inputParameters.push(
            formatInput(bpmnFactory, inputOutput, {
              name,
              value: inputValueMapper(value, { type, config })
            })
          );
      }
    });
  }

  render() {
    return (
      <div className="bpmn">
        <div className="palette">
          <div className="palette-undo-block" />
          <div className="palette-group">
            <button className={"button"} onClick={this.onButtonLassoSelect}>
              <i className={"anticon-icon edition-7"} />
            </button>
            <button className={"button"} onClick={this.onButtonLink}>
              <i className={"anticon-icon keyboard-15"} />
            </button>
          </div>
          {_.map(this.toolbar, (buttons, group) => (
            <div className="palette-group" key={group}>
              {_.orderBy(buttons, ["priority"]).map((component, idx) => {
                const height = 32,
                  width = 32;
                const iconSize = 19;
                const iconStyle = {
                  color: component.color,
                  fontSize: (component.icon_scale || 1) * iconSize
                };

                let iconRotate = component.icon_rotate || 0;

                const offset_x =
                  component.toolbar_offset_x !== undefined
                    ? component.toolbar_offset_x
                    : component.offset_x;
                const offset_y =
                  component.toolbar_offset_y !== undefined
                    ? component.toolbar_offset_y
                    : component.offset_y;

                iconStyle.marginLeft = (offset_x / 200) * width;
                iconStyle.marginTop = (offset_y / 200) * height;
                const iconBorderStyle = {
                  borderWidth: component.border / 2,
                  borderColor: component.color
                };
                switch (component["class"]) {
                  case "gateway":
                    iconBorderStyle.height = height / Math.sqrt(2);
                    iconBorderStyle.width = width / Math.sqrt(2);
                    iconBorderStyle.transform = "rotate(45deg)";
                    iconBorderStyle.marginLeft = 0;

                    iconRotate -= 45;
                    break;
                  case "event":
                    iconBorderStyle.borderRadius = 20;
                    break;
                  default:
                  //NOPE
                }

                if (iconRotate) {
                  iconStyle.transform = `rotate(${iconRotate}deg)`;
                }

                return (
                  <button
                    title={component.title}
                    key={component.element + "#" + component.service}
                    className="button"
                    draggable={true}
                    style={{
                      paddingLeft: component["class"] === "gateway" ? 4 : 0
                    }}
                    onClick={event =>
                      this.onButtonElement(component).click(event.nativeEvent)
                    }
                    onDragStart={event =>
                      this.onButtonElement(component).dragstart(
                        event.nativeEvent
                      )
                    }
                  >
                    <div style={iconBorderStyle} className="icon-border">
                      <i
                        className={classnames([component.icon, "icon"])}
                        style={iconStyle}
                      />
                    </div>
                  </button>
                );
              })}
            </div>
          ))}
        </div>
        <div className="canvas">
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            className="bpmn-header"
          >
            <h2 className="text-overflow-ellipsis">
              {this.props.file ? this.props.file.title : ""}
            </h2>
            <Row type="flex" align="middle">
              <Button onClick={this.onSave} type="primary">
                {i18n.t("buttons.save")}
              </Button>
              <ButtonClose
                className={"bpmn-header-close"}
                large
                onClick={this.props.onCancel}
              />
            </Row>
          </Row>
          <div
            ref={ref => {
              this.ref = ref;
            }}
          />
        </div>
      </div>
    );
  }
}

Editor.propTypes = {
  bpmn: PropTypes.string.isRequired,
  onSelectElement: PropTypes.func.isRequired,
  toolbar: PropTypes.array.isRequired
};

export default withTranslation(undefined, { withRef: true })(Editor);
