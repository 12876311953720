import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { Row } from "antd";

import filterActions from "../../../../../../../actions/filterActions";
import { connect } from "../../../../../../StateProvider";
import Filters from "../../../../../../Filters";
import filtersUtils from "../../../../../../../utils/filters";
import UrlParamSync from "../../../../../../UrlParamSync";

import ButtonTransparent from "../../../../../../common/UI/ButtonTransparent";

import styles from "./catalogBody.less";
class FilterList extends React.Component {
  static propTypes = {
    catalog: PropTypes.object,
    viewId: PropTypes.string,
    sceneId: PropTypes.string,
    additionalFilters: ImmutablePropTypes.map,
    catalogId: PropTypes.string,
    withHeader: PropTypes.bool,
    readOnly: PropTypes.bool
  };

  onSave = (filterId, fieldId, value) => {
    const { sceneId, catalogId, viewId } = this.props;

    filterActions.updateFieldFilter(
      {
        sceneId,
        catalogId,
        viewId,
        fieldId,
        filterId
      },
      value
    );
  };

  clearFilters = () => {
    this.onFiltersChange( Immutable.Map() );
  };

  onFiltersChange = filters => {
    const { sceneId, catalogId, viewId } = this.props;
    filterActions.updateFieldFilters({ sceneId, catalogId, viewId }, filters);
  };

  render() {
    let {
      catalog,
      fields,
      withHeader,
      filters,
      readOnly,
      filtersChanged,
      t
    } = this.props;
    if (!(fields && fields.size)) {
      return null;
    }
    
    return (
      <div className={styles.filtersContainer}>
        {withHeader ? (
          <Row align="middle" className={styles.filtersHeader}>
            {t("filter.header")}
            {(filters && filters.size) ? (
              <ButtonTransparent
                className={styles.filtersClear}
                onClick={this.clearFilters}
              >
                {t("filter.clearAll")}
              </ButtonTransparent>
            ) : null}
          </Row>
        ) : null}
        <div className={styles.filtersData}>
          <Filters
            catalogId={catalog.get("id")}
            fields={fields}
            filters={filters}
            readOnly={readOnly}
            onSave={this.onSave}
            withExtendedFilters={true}
          />
        </div>
        <UrlParamSync
          name="filters"
          value={filtersChanged ? filters : undefined}
          base64={true}
          onChange={this.onFiltersChange}
        />
      </div>
    );
  }
}

FilterList.defaultProps = {
  withHeader: true,
  readOnly: false
};

export default withTranslation()(
  connect(
    FilterList,
    {
      scenes: ["scenes"]
    },
    (props, { scenes }) => {
      const { additionalFilters, catalog } = props;
      const catalogId = catalog && catalog.get("id");
      const view = scenes.getIn([props.sceneId, "views", props.viewId]);
      const fields = catalog && catalog.get("fields");
      const filtersChanged = view && view.get("filtersChanged");
      let filters = view && view.get("filters");

      if (additionalFilters) {
        filters = filtersUtils.mergeFilters(filters, additionalFilters);
      }

      return {
        filters,
        fields,
        catalogId,
        filtersChanged,
        ...props
      };
    }
  )
);
