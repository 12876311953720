import React from "react";
import Immutable from "immutable";
import TabsMenu from "../../common/menu/TabsMenu";
import DefaultRedirect from "../../common/router/DefaultRedirect";
import routes from "../../../routes";
import NavRoute from "../../common/router/Route";
import mapRouteParams from "../../common/router/mapRouteParams";
import apiActions from "../../../actions/apiActions";
import { connect } from "../../StateProvider";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { prompt } from "../../common/Modal";
import { alert } from "../../common/Modal";

import PRIVILEGE_CODES from "../../../configs/privilegeCodes";
import RESOURCE_TYPES from "../../../configs/resourceTypes";
import { checkAccessOnObject } from "../../../utils/rights";

import uiActions from "../../../actions/uiActions";

import styles from "./appHeader.less";

const IconForSectionDefault = "content-3";
let sectionsMenuHoverTimer = null;
class SectionsMenu extends React.PureComponent {
  state = {
    reordering: false
  };

  componentDidMount() {
    apiActions.getSections();

    this.setState({
      sections: this.sortSections(this.props.sections)
    });
  }

  // todo remove this workaround
  // when api with "'available' privilege" feature becomes
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.sections.get("1") &&
      !nextProps.sectionsOptions.getIn(["sectionsLoading", "1"]) &&
      !nextProps.sectionsOptions.getIn(["sectionsLoaded", "1"])
    ) {
      apiActions.getSection({ sectionId: "1" });
    }

    if (nextProps.sections !== this.props.sections && !this.state.reordering) {
      this.sortSections(nextProps.sections);
    }
  }

  // Check if language changed to push "addSection button"
  // with right translation
  componentDidUpdate(prevProps) {
    if (this.props.t !== prevProps.t) {
      this.sortSections(this.props.sections);
    }
  }

  sortSections = sections => {
    let hasAdd = false;

    sections = sections
      .valueSeq()
      .sortBy(s => s.get("priority"))
      .map(s => s.remove("icon"))
      .map(s => s.set("title", s.get("name")))
      .map(s => s.set("badge", s.get("newChats")))
      // show sections with privilegeCode > available (search, view, ...)
      .filter(section => section.get("privilegeCode") !== "available")

    const adminSection = sections.find(section => {
      return Number(section.get("id")) === 1;
    });

    if (adminSection) {
      hasAdd = checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        adminSection,
        PRIVILEGE_CODES.ADMIN
      );
    }

    if (hasAdd) {
      sections = sections.concat([
        Immutable.Map({
          id: "addButton",
          name: "+ " + this.props.t("section.menu.create"),
          onClick: this.openCreateModal
        })
      ]);
    }

    this.setState({ sections });
  };

  openCreateModal = () => {
    const { t } = this.props;
    if (this.props.sectionsOptions.get("sectionCreating")) {
      return;
    }
    prompt({
      headerText: t("modals.createSection.headerText"),
      placeHolder: t("modals.createSection.inputPlaceHolder"),
      okText: t("buttons.create"),
      cancelText: t("buttons.cancel"),
      onOk: this.onCreateSection
    });
  };

  onCreateSection = name => {
    apiActions
      .createSection(
        {},
        {
          name: name,
          icon: IconForSectionDefault
        }
      )
      .then(res => {
        this.props.history.push({
          pathname: `/section/${res.id}`,
          search: this.props.location.search
        });
      });
  };

  filterFn = (idArr, textSearch) => {
    let arr = [];
    idArr.forEach(id => {
      this.props.sections.forEach(section => {
        if (section.get("id") === id) {
          if (
            section
              .get("name")
              .toLowerCase()
              .indexOf(textSearch) !== -1
          ) {
            arr.push(id);
          }
        }
      });
    });
    arr.push("addButton");
    return arr;
  };

  isAdminOfSection = key => {
    const section = this.props.sections.get(key);
    const isAdmin =
      section &&
      checkAccessOnObject(
        RESOURCE_TYPES.SECTION,
        section,
        PRIVILEGE_CODES.ADMIN
      );

    return isAdmin;
  };

  // hover menu
  // with delay to prevent not needed selections
  onClick( item, e ) {
    uiActions.sectionMenuHover(item.get("id"));
    e.stopPropagation();
    return false;
  }
  onMouseEnter( item ) {
    sectionsMenuHoverTimer = setTimeout(()=>{
      uiActions.sectionMenuHover(item.get("id"));
    }, 75);
  }
  onMouseLeave( item ) {
    clearTimeout(sectionsMenuHoverTimer);
  }

  /* dnd */
  canDrag = dragItem => {
    if (dragItem.key === "addButton") {
      return false;
    }

    const isAdmin = this.isAdminOfSection(dragItem.key);
    return isAdmin;
  };

  canDrop = (dragItem, dropItem) => {
    if (dropItem === "addButton") {
      return false;
    }

    const { sectionId } = this.props;
    // если перетаскиваем каталог в свою же секцию
    if (dropItem.key === sectionId) {
      return false;
    }

    // в секцию можно перетащить либо секцию, либо каталоги
    const allowedDragTypes = [RESOURCE_TYPES.CATALOG, RESOURCE_TYPES.SECTION];
    if (!allowedDragTypes.find(item => item === dragItem.dragType)) {
      return false;
    }

    // если нет прав на секцию
    const isSectionAdmin = this.isAdminOfSection(dropItem.key);
    if (!isSectionAdmin) {
      return false;
    }

    return true;
  };

  onDrop = (dragItem, dropItem, newOrderList) => {
    if (dragItem.dragType === RESOURCE_TYPES.CATALOG) {
      const catalogId = dragItem.key;
      const sectionId = dropItem.key;

      const params = { sectionId, catalogId };
      // const data = { sectionId };

      // might be useful
      // let data = {
      //   fields: catalogs.getIn([catalogId, "fields"]),
      //   icon: catalogs.getIn([catalogId, "icon"]),
      //   name: catalogs.getIn([catalogId, "name"]),
      //   sectionId: nextItem.key
      // };

      let data = {
        sectionId: dropItem.key
      };

      apiActions
        .updateCatalog(params, data)
        .then(() => {
          return this.props.history.push({
            pathname: `/section/${sectionId}/catalog/${catalogId}`,
            search: this.props.history.location.search
          });
        })
        .catch(e =>
          alert({
            headerText: this.props.t("modals.moveCatalogError.headerText"),
            text: _.get(e, "response.text"),
            okText: this.props.t("modals.moveCatalogError.okText")
          })
        );
    }

    if (dragItem.dragType === RESOURCE_TYPES.SECTION) {
      let sections = this.state.sections;
      let orderedList = newOrderList;
      const key = dragItem.key;

      // calc new priority
      orderedList = orderedList.toJS();
      orderedList = orderedList.filter(i => i != "addButton");
      let index = orderedList.findIndex(i => i == key);

      if (index > 0) {
        const prevKey = orderedList[index - 1]; // get key of previous section
        const section = sections.find(section => {
          return section.get("id") === prevKey;
        });
        index = section.get("priority") + 1;
      }

      // update in local
      sections = this.props.sections;
      let prevIndex;
      sections = sections.map(section => {
        if (section.get("id") == key) {
          prevIndex = section.get("priority");
          section = section.set("priority", index - 0.1); // to be sure be in need place
        }
        return section;
      });
      this.sortSections(sections);
      this.setState({ reordering: true });

      // save
      apiActions
        .updateSection(
          {
            sectionId: key
          },
          {
            priority: index
          }
        )
        .then(() => {
          this.setState({ reordering: false });
        })
        .catch(() => {
          this.setState({ reordering: false });
          // put in previous place
          sections = this.props.sections;
          sections = sections.map(section => {
            if (section.get("id") == key) {
              section = section.set("priority", prevIndex);
            }
            return section;
          });
          this.sortSections(sections);
        });
    }
  };
  /* end dnd */

  render() {
    const sections = this.state.sections || Immutable.List();
    const firstSection = sections.get(0);
    return (
      <div className={styles.menuContainer}>
        <DefaultRedirect
          route={routes.section}
          params="sectionId"
          object={firstSection}
        />
        <NavRoute
          route={routes.section}
          render={props => {
            const sectionId = props.match?.params?.sectionId;
            const currentSectionId = this.props.hoveredSectionId || sectionId || firstSection
            return (
              <TabsMenu
                //route={routes.section}
                params="sectionId"
                items={sections}
                activeId={currentSectionId}
                dragType="section"
                canDrag={this.canDrag}
                canDrop={this.canDrop}
                onDrop={this.onDrop}
                onClick={this.onClick}
                //onMouseEnter={this.onMouseEnter}
                //onMouseLeave={this.onMouseLeave}
                filterFn={this.filterFn}
                theme="light"
              />
            );
          }}
        />
        
      </div>
    );
  }
}

export default connect(
  mapRouteParams(withTranslation()(SectionsMenu), routes.section),
  {
    sections: ["sections"],
    sectionsOptions: ["sectionsOptions"],
    hoveredSectionId: ["ui", "hoveredSectionId"]
  }
);
