import { Link } from "react-router-dom";
import cn from "classnames";
import React from "react";
import _ from "lodash";

import Icon from "../../UI/Icon";
import Badge from "../../UI/Badge";
import AbstractMenu from "../AbstractMenu";
import NavLink from "../../router/Link";

import defaultTheme from "./defaultTheme.less";
import lightTheme from "./lightTheme.less";

const TabsMenu = ({ ...props }) => {
  const {
    activeId,
    items,
    route,
    params,
    extParams = {},
    dragType,
    canDrag,
    canDrop,
    onDrop,
    onMouseEnter,
    onMouseLeave,
    className,
    linkClassName,
    filterFn,
    theme,
    onClick
  } = props;
  let styles = {};
  let buttonMoreProps;

  switch (theme) {
    case "light":
      styles = lightTheme;
      buttonMoreProps = { light: true };
      break;
    default:
      styles = defaultTheme;
  }

  const horizontal = {
    menu: styles.horizontalMenu,
    item: styles.listItem
  };
  const vertical = {
    menu: styles.verticalMenu
  };

  const getIcon = item => {
    if (_.isObject(item.get("icon"))) {
      return React.cloneElement(item.get("icon"), { className: cn(styles.icon, item.get("icon").props.className) });
    } else if ( item.get("icon") ) {
      return <Icon type={"icon " + item.get("icon")} className={styles.icon} />;
    }
  };
  const getTextName = item => {
    if ( item.get("badge") && item.get("name") ) {
      return (
          <Badge dot={item.get("badge")===true} count={item.get("badge")} className={styles.iconBadge}>
            <span
              className={item.get("error") ? styles.textError : styles.text}    
              onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
              onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
            >
                {item.get("name")}
            </span>
          </Badge>
      )
    } else if ( item.get("name") ) {
      return (
        <span
          className={item.get("error") ? styles.textError : styles.text}
          onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
          onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
        >
            {item.get("name")}
        </span>
      )
    }

  };

  const getNameActive = item => (
    <span
      className={styles.text}
      onMouseEnter={() => onMouseEnter && onMouseEnter(item)}
      onMouseLeave={() => onMouseLeave && onMouseLeave(item)}
    >
      {item.get("nameActive")}
    </span>
  );
  const getNavLink = (item, linkParams, title, icon, textActive, text) => (
    <NavLink
      key={item.get("id")}
      route={item.get("route") || route}
      params={linkParams}
      render={props => {
        return (
          <Link
            onClick={(e) => onClick && onClick(item, e)}
            to={props.link}
            title={title}
            className={cn(styles.link, linkClassName, {
              [styles.selected]: activeId ? activeId === item.get("id") : props.isActive
            })}
          >
            <div className={styles.data}>
              {icon}
              {props.isActive ? textActive : text}
            </div>
          </Link>
        );
      }}
    />
  );
  const getNativeLink = (item, title, icon, text) => (
    <a
      key={item.get("id")}
      rel="noreferrer"
      className={cn(styles.link, styles.addButton, {
        [styles.selected]: activeId === item.get("id")
      })}
      onClick={(e) => {
        const callFn = item.get("onClick");
        callFn && callFn();
        onClick && onClick(item, e);
      }}
      title={title}
    >
      <div className={styles.data}>
        {icon}
        {text}
      </div>
    </a>
  );

  const getItems = items => {
    return items.map((item, i) => {
      const icon = item.get("icon") ? getIcon(item) : null;
      const title = item.get("title") || item.get("name");
      const text = item.get("name") && getTextName(item);
      const textActive =
        (item.get("nameActive") && getNameActive(item)) || text;
      const linkWithRoute =
        item.get("route") || (route && !item.get("onClick"));

      if (linkWithRoute) {
        const linkParams = Object.assign(
          {},
          extParams,
          item.get("params") && { [item.get("params")]: item.get("id") },
          params && { [params]: item.get("id") }
        );
        return getNavLink(item, linkParams, title, icon, textActive, text);
      } else {
        return getNativeLink(item, title, icon, textActive, text);
      }
    });
  };

  return (
    <AbstractMenu
      horizontal={horizontal}
      vertical={vertical}
      dragType={dragType}
      canDrag={canDrag}
      canDrop={canDrop}
      onDrop={onDrop}
      className={className}
      filterFn={filterFn}
      buttonMoreProps={buttonMoreProps}
      items={getItems(items)}
    />
  );
};

export default TabsMenu;
