// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".viewSelector__container___v9AU\\+ {\n  width: 100%;\n}\n.viewSelector__viewsSelector___mPBGa {\n  width: 100%;\n}\n.viewSelector__viewsSelector___mPBGa .ant-select-selector {\n  background: #eff1f4 !important;\n  border-color: transparent !important;\n}\n.viewSelector__viewsSelector___mPBGa.ant-select-focused .ant-select-selector {\n  box-shadow: none !important;\n  border-color: rgba(0, 0, 0, 0.1) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/App/AppBody/Section/SectionBody/Catalog/View/ViewHeader/ViewSelector/viewSelector.less"],"names":[],"mappings":"AAGA;EACE,WAAA;AAFF;AAKA;EACE,WAAA;AAHF;AAKE;EACE,8BAAA;EACA,oCAAA;AAHJ;AAME;EAEI,2BAAA;EACA,2CAAA;AALN","sourcesContent":["@import (reference) \"../../../../../../../../../styles/helpers/index.less\";\n\n\n.container {\n  width: 100%;\n}\n\n.viewsSelector {\n  width: 100%;\n\n  & :global(.ant-select-selector) {\n    background: @BG_HEADER !important;\n    border-color: transparent !important;\n  }\n\n  &:global(.ant-select-focused .ant-select-selector) {\n    //.ant-select-focused .ant-select-selector {\n      box-shadow: none !important;\n      border-color: @BORDER_INPUT !important;\n    //}\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "viewSelector__container___v9AU+",
	"viewsSelector": "viewSelector__viewsSelector___mPBGa"
};
export default ___CSS_LOADER_EXPORT___;
